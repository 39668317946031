import { theme } from "../../utils/theme";

export const styles = {
  decoration: {
    height: "4px",
    mr: 3,
    width: { xs: 48, md: 52 },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  header: {
    alignItems: "center",
    display: "flex",
  },
  headerRoot: {
    display: "flex",
    flexDirection: "column",
    width: { xs: "100%", md: "100%" },
  },
  headerText: {
    fontSize: {
      xs: theme.typography.h1,
      md: theme.typography.h1,
    },
    fontWeight: 700,
    letterSpacing: 0.9,
    lineHeight: { xs: 1.1, md: 1.1 },
    textTransform: "capitalize",
  },
  margin: {
    display: "flex",
    margin: theme.spacing(1),
  },
  subheader: {
    alignItems: "center",
    fontSize: {
      xs: theme.typography.body2,
      md: theme.typography.body2,
    },
    fontWeight: 600,
    letterSpacing: 0.9,
    lineHeight: 1.1,
    textTransform: "uppercase",
  },
};
