import { body, theme } from "../../../utils/theme";

export const styles = {
  dialog: {
    backgroundColor: theme.palette.background.default,
    overflow: "auto",
  },
  dialogBackground: {
    filter: "brightness(0.9) blur(2px)",
    height: "100vh",
    right: 0,
    top: 0,
    overflow: "hidden",
    position: "absolute",
    width: "100vw",
    zIndex: 0,
  },
  dialogHeader: {
    alignItems: "center",
    color: theme.palette.grays[800],
    display: "flex",
    paddingBottom: 4,
    paddingLeft: "0px",
    paddingRight: "7px",
    paddingTop: "17px",
    width: body.width,
  },
  dialogHeaderContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
  },
  dialogListContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: 600,
    justifyContent: "flex-start",
    pb: 6,
    pl: 9,
    pr: 9,
    pt: 8,
    width: "100%",
  },
  dialogVideo: {
    height: "100%",
    left: 0,
    position: "absolute",
    width: { xs: "inherit", md: "100%" },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  headerIcon: {
    alignItems: "center",
    display: "flex",
    color: theme.palette.secondary.main,
    justifyContent: "center",
  },
  icon: {
    alignItems: "center",
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    ml: 3,
  },
  margin: {
    display: "flex",
    margin: theme.spacing(1),
  },
  navMenuContainer: {
    alignItems: "center",
    color: theme.palette.secondary.main,
    display: { md: "none", xs: "flex" },
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  navSubtextBody: {
    color: theme.palette.grays[600],
    fontSize: theme.typography.body2,
    fontWeight: 500,
  },
  navSubtextHeader: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.h5,
    fontWeight: 700,
    letterSpacing: 1.2,
    textTransform: "inherit",
  },
  navSubtextContainer: {
    alignItems: "center",
    display: "flex",
    marginLeft: 12,
  },
  navText: {
    alignItems: "center",
    display: "flex",
    color: theme.palette.secondary.main,
    fontSize: theme.typography.h4,
    fontWeight: 500,
    letterSpacing: 1.2,
    textDecoration: "none",
    textTransform: "capitalize",
  },
};
