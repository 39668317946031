import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import Header from "../Header";
import SectionLayout from "../../layouts/SectionLayout";
import SlideTransition from "../SlideTransition";
import TeamItem from "../TeamItem";

import useContent from "../../hooks/useContent";
import useInView from "../../hooks/useInView";
import { MAIN_ROUTES } from "../../utils/routes";

import { styles } from "./style";

const Team = () => {
  const content = useContent();
  const [setRef, visible] = useInView({
    threshold: 0.05,
  });

  return (
    <SectionLayout id={MAIN_ROUTES.get("PEOPLE").ID}>
      <Stack
        spacing={20}
        alignItems={"center"}
        justifyContent={"center"}
        sx={styles.root}
      >
        {/* <SlideTransition appear={visible} timeout={800}> */}
        <Stack spacing={2}>
          <Header center={true} data={content.team} decorationPos={"bottom"} />

          <Typography sx={styles.bodyText}>{content.team.body}</Typography>
        </Stack>
        {/* </SlideTransition> */}

        <Grid
          container
          direction={"row"}
          ref={setRef}
          spacing={8}
          sx={styles.body}
        >
          {content.team.members
            .filter((f) => f.position === "professor")
            .map((item, idx) => {
              return <TeamItem data={item} index={idx} visible={visible} />;
            })}
          {content.team.members
            .filter((f) => f.position === "researchFellow")
            .map((item, idx) => {
              return <TeamItem data={item} index={idx} visible={visible} />;
            })}
          {content.team.members
            .filter((f) => f.position === "phdCandidate")
            .map((item, idx) => {
              return <TeamItem data={item} index={idx} visible={visible} />;
            })}
            {content.team.members
            .filter((f) => f.position === "researchAssistant")
            .map((item, idx) => {
              return <TeamItem data={item} index={idx} visible={visible} />;
            })}
        </Grid>

        <Stack spacing={2}>
          <Header
            center={true}
            data={content.external_collaborators}
            decorationPos={"bottom"}
          />
        </Stack>

        <Grid container direction={"row"} spacing={8} sx={styles.body}>
          {content.external_collaborators.members.map((item, idx) => {
            return <TeamItem data={item} index={idx} visible={true} />;
          })}
        </Grid>
      </Stack>

      <Box sx={styles.backgroundDecoContainer}>
        <Box className="background_spin" sx={styles.backgroundDeco1} />
        <Box className="background_spin" sx={styles.backgroundDeco2} />
        <Box className="background_spin" sx={styles.backgroundDeco3} />
      </Box>

      <Box sx={styles.background}>
        {/* <Slide in={visible} timeout={300} direction={"left"}>
          <Box sx={styles.backgroundOverlayTopBefore} />
        </Slide>

        <Slide in={visible} timeout={400} direction={"left"}>
          <Box sx={styles.backgroundOverlayTopAfter} />
        </Slide> */}
      </Box>
    </SectionLayout>
  );
};

export default Team;
