import { Box } from "@mui/material";
import React, { lazy, Suspense, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, Routes, useLocation } from "react-router-dom";

import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import Loader from "./components/LoadingScreen";
import Nav from "./components/navComponents/Nav";

import useContent from "./hooks/useContent";
import { MAIN_ROUTES, RESOURCES_ROUTES } from "./utils/routes";
import { appBar } from "./utils/theme";

const BlogPage = lazy(() => import("./pages/BlogPage"));
const BlogListPage = lazy(() => import("./pages/BlogListPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const EventsListPage = lazy(() => import("./pages/EventsListPage"));
const EventPage = lazy(() => import("./pages/EventPage"));
const PeoplePage = lazy(() => import("./pages/PeoplePage"));
const PublicationPage = lazy(() => import("./pages/PublicationPage"));

function App() {
  const content = useContent();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{content.meta.title}</title>

        <meta name="description" content={content.meta.description} />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Kulim+Park&display=swap"
          rel="stylesheet"
        />

        {/** Social media share card properties */}
        <meta property="og:url" content="https://neurdb.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={content.meta.title} />
        <meta property="og:description" content={content.meta.description} />
        <meta property="og:image" content="https://i.imgur.com/oHVrKco.png" />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <Box
        sx={{
          cursor: "default",
          minHeight: "100vh",
          overflowX: { xs: "hidden", sm: "hidden", md: "clip" },
          pt: `${appBar.height}px`,
          width: "100%",
        }}
      >
        <Nav />

        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={MAIN_ROUTES.get("HOME").PATH} element={<HomePage />} />

            <Route
              path={MAIN_ROUTES.get("PEOPLE").PATH}
              element={<PeoplePage />}
            />

            <Route
              path={MAIN_ROUTES.get("PUBLICATIONS").PATH}
              element={<PublicationPage />}
            />

            <Route
              path={MAIN_ROUTES.get("EVENT").PATH}
              element={<EventPage />}
            />

            <Route
              path={RESOURCES_ROUTES.get("EVENTS_LIST").PATH}
              element={<EventsListPage />}
            />

            <Route
              path={MAIN_ROUTES.get("BLOG").PATH}
              element={<BlogPage />}
            />

            <Route
              path={RESOURCES_ROUTES.get("BLOG_LIST").PATH}
              element={<BlogListPage />}
            />

            <Route
              path={MAIN_ROUTES.get("CONTACT").PATH}
              element={<ContactPage />}
            />

            <Route path="/aixdb" element={<AixdbPage />} />
          </Routes>
        </Suspense>

        <Footer />
      </Box>
    </HelmetProvider>
  );
}

function AixdbPage() {
  useEffect(() => {
    window.location.href = "/aixdb.html";
  }, []);

  return null;
}

export default App;
