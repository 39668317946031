import cai_sf from "../assets/team/cai_sf.jpg";
import kianleetan from "../assets/team/kianleetan.jpg";
import xiaokuixiao from "../assets/team/xiaokuixiao.jpg";
import xing_naili from "../assets/team/xing_naili.jpg";
import ooibc_s from "../assets/team/ooibc_s.jpg";
import yue_cong from "../assets/team/yue_cong.jpg";
import zenglingze from "../assets/team/zenglingze.png";
import gao_haotian from "../assets/team/gao_haotian.png";
import xiang_siqi from "../assets/team/xiang_siqi.jpg";
import zhanhao from "../assets/team/zhanhao.jpg";

const team = [
  {
    image: ooibc_s,
    header: "Ooi Beng Chin",
    content:
      "Lee Kong Chian Centennial Professor, National University of Singapore",
    action: "",
    link: "",
    position: "professor",
  },
  {
    image: kianleetan,
    header: "Tan Kian-Lee",
    content:
      "Tan Sri Runme Shaw Senior Professor, National University of Singapore",
    action: "",
    link: "",
    position: "professor",
  },
  {
    image: xiaokuixiao,
    header: "Xiao Xiaokui",
    content: "Professor, National University of Singapore",
    action: "",
    link: "",
    position: "professor",
  },
  {
    image: cai_sf,
    header: "Cai Shaofeng",
    content: "Senior Research Fellow, National University of Singapore",
    action: "",
    link: "",
    position: "researchFellow",
  },
  {
    image: yue_cong,
    header: "Cong Yue",
    content: "Research Fellow, National University of Singapore",
    action: "",
    link: "",
    position: "researchFellow",
  },
  {
    image: zhanhao,
    header: "Zhao Zhanhao",
    content: "Research Fellow, National University of Singapore",
    action: "",
    link: "",
    position: "researchFellow",
  },
  {
    image: xing_naili,
    header: "Xing Naili",
    content: "PhD Candidate, National University of Singapore",
    action: "",
    link: "",
    position: "phdCandidate",
  },
  {
    image: zenglingze,
    header: "Zeng Lingze",
    content: "PhD Candidate, National University of Singapore",
    action: "",
    link: "",
    position: "phdCandidate",
  },
  {
    image: gao_haotian,
    header: "Gao Haotian",
    content: "PhD Candidate, National University of Singapore",
    action: "",
    link: "",
    position: "phdCandidate",
  },
  {
    image: xiang_siqi,
    header: "Xiang Siqi",
    content: "Research Assistant, National University of Singapore",
    action: "",
    link: "",
    position: "researchAssistant",
  },
];

export default team;
