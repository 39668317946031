import {
  Box,
  Container,
  Dialog,
  Fade,
  Grow,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowRightLine, RiCloseLine, RiMenuLine } from "react-icons/ri";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";
import { TbPoint } from "react-icons/tb";
import { Link } from "react-router-dom";

import Logo from "../../Logo";

import useInView from "../../../hooks/useInView";
import { NAV_OPTIONS } from "../../../utils/optionUtils";
import { theme } from "../../../utils/theme";

import { styles } from "./style";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} timeout={300} {...props} />;
});

const NavMenu = () => {
  const [setRef, visible] = useInView({
    threshold: 0.1,
  });
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOnClickNavigate = (event) => {
    setIsMenuOpen(false);
  };

  return (
    <Box sx={styles.navMenuContainer} ref={setRef}>
      <Box sx={styles.headerIcon}>
        <RiMenuLine onClick={() => setIsMenuOpen(true)} size="1.2em" />
      </Box>

      <Dialog
        fullScreen
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          style: { ...styles.dialog },
        }}
        TransitionComponent={Transition}
      >
        <Box sx={styles.dialogHeaderContainer}>
          <Box sx={styles.dialogHeader} onClick={() => setIsMenuOpen(false)}>
            <Logo />

            <Box sx={styles.grow} />

            <RiCloseLine onClick={() => setIsMenuOpen(false)} size="1.3em" />
          </Box>
        </Box>

        <Box sx={styles.dialogListContainer}>
          <Stack spacing={5}>
            {Array.from(NAV_OPTIONS).map(([key, value], idx) =>
              value.ITEMS.length > 0 ? (
                <Box key={key}>
                  <Grow in={visible} timeout={800}>
                    <Typography style={styles.navText}>
                      {t(value.LABEL)}
                    </Typography>
                  </Grow>

                  <Stack spacing={5} sx={{ mb: 2, mt: 3 }}>
                    {value.ITEMS.map(([key, value], index) => {
                      return (
                        <Grow
                          key={index}
                          in={visible}
                          timeout={1000 + index * 300}
                        >
                          <Link
                            key={index}
                            onClick={handleOnClickNavigate}
                            // rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                            // target="_blank"
                            to={value.PATH}
                          >
                            <Box style={styles.navSubtextContainer}>
                              <TbPoint
                                size={"1.1em"}
                                style={{
                                  color: theme.palette.primary.main,
                                  marginRight: 4,
                                }}
                              />
                              <Stack spacing={0} sx={styles.selectOption}>
                                <Box sx={styles.navSubtextHeader}>
                                  {t(value.LABEL)}
                                </Box>
                                <Box sx={styles.navSubtextBody}>
                                  {t(value.DESCRIPTION)}
                                </Box>
                              </Stack>

                              {/* <HiArrowTopRightOnSquare
                                size={"1em"}
                                style={{
                                  marginLeft: 4,
                                }}
                              /> */}
                              <Box sx={styles.grow} />
                            </Box>
                          </Link>
                        </Grow>
                      );
                    })}
                  </Stack>
                </Box>
              ) : (
                <Grow key={key} in={visible} timeout={800}>
                  <Link
                    key={key}
                    onClick={handleOnClickNavigate}
                    style={styles.navText}
                    rel={value.EXTERNAL ? "noopener noreferrer" : ""}
                    target={value.EXTERNAL ? "_blank" : ""}
                    to={value.PATH}
                  >
                    {t(value.LABEL)}

                    <Box sx={styles.icon}>
                      <RiArrowRightLine />
                    </Box>
                  </Link>
                </Grow>
              )
            )}
          </Stack>

          <Box sx={styles.grow} />

          <Link
            style={{
              textDecoration: "none",
            }}
            to={"https://www.comp.nus.edu.sg/~dbsystem/"}
            rel={"noopener noreferrer"}
            target={"_blank"}
          >
            <Typography
              sx={{
                ...styles.navText,
                color: theme.palette.primary.dark,
              }}
            >
              DB System
            </Typography>
          </Link>
        </Box>
      </Dialog>
    </Box>
  );
};

export default NavMenu;
