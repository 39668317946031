import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const FONT_SIZES = {
  h1: "3rem",
  h2: "2rem",
  h3: "1.5rem",
  h4: "1.13rem",
  h5: "1.08rem",
  body: "1rem",
  body2: "0.88rem",
  body3: "0.75rem",
  body4: "0.68rem",
};

// Create a theme instance.
export const theme = createTheme({
  spacing: 4,
  typography: {
    fontSize: 20,
    fontFamily: [
      '"Segoe UI"',
      "Kulim Park",
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
    ...FONT_SIZES,
    useNextVariants: true,
  },
  palette: {
    background: {
      default: "#fff",
      muted: "rgba(217, 231, 255, 0.5)",
      dark: "#0a2f4e",
      gradient: "linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%)",
    },
    primary: {
      main: "#0e8ff7",
      light: "#F6F9FC",
    },
    secondary: {
      main: "#113F63",
    },
    accent: {
      main: "#46E8F3",
    },
    danger: {
      main: red.A400,
    },
    grays: {
      white: "#fff",
      100: "#f6f8fa",
      200: "#f0f2f5",
      300: "#e4e6ef",
      400: "#b6b6c4",
      500: "#a2a6b8",
      600: "#7e8299",
      700: "#5e6278",
      800: "#4f5058",
      900: "#404254",
      1000: "#121526",
      1100: "#07080f",
      black: "#333",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          fontSize: "14px",
          fontWeight: 500,
          gap: 2,
          height: "fit-content",
          letterSpacing: 2,
          minWidth: 148,
          paddingBottom: 12,
          paddingLeft: 20,
          paddingRight: 16,
          paddingTop: 12,
          textTransform: "capitalize",
          transition: "all 0.5s ease-in-out",
          width: "fit-content",
        },
        contained: {
          boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.08)",
          "&:hover": {
            boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.08)",
          },
        },
        outlined: {
          boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.08)",
          "&:hover": {
            border: `1px solid ${"#e4e6ef"}`,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          // background: "linear-gradient(90deg, #7C1CFD -5.73%, #139AF6 88.78%)",
        },
      },
    },
  },
});

export const appBar = {
  height: 64,
  width: "100%",
};

export const body = {
  color: "#5e6278", // grays[700]
  fontSize: 20, // x1
  fontWeight: 400,
  maxWidth: 1250,
  width: "95%",
};

export const footer = { height: { xs: "inherit", md: 340 } };

export const glass = {
  background: "rgba(255, 255, 255, 0.8)",
  backdropFilter: "saturate(180%) blur(20px)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  boxShadow: "0px 4px 12px rgba(184, 184, 184, 0.25)",
};
