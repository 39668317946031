import { theme } from "../../utils/theme";

export const styles = {
  item: {
    alignItems: { xs: "center", md: "start" },
    display: "flex",
    justifyContent: "start",
    pb: 10,
    pt: { xs: 10, md: 15 },
    minHeight: { xs: "fit-content", md: "75vh" },
  },
  itemAction: {
    alignItems: "center",
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    fontSize: {
      xs: theme.typography.fontSize * 0.88,
      md: theme.typography.fontSize * 0.88,
    },
    fontWeight: 600,
  },
  itemBullet: {
    alignItems: "center",
    background: theme.palette.background.gradient,
    borderRadius: 2,
    color: theme.palette.grays.white,
    display: "flex",
    fontSize: {
      xs: theme.typography.h3,
      md: theme.typography.h3,
    },
    height: 40,
    justifyContent: "center",
    width: 40,
  },
  itemHeader: {
    alignItems: { xs: "center", md: "start" },
  },
  itemHeaderText: {
    color: theme.palette.secondary.main,
    fontSize: {
      xs: theme.typography.h2,
      md: theme.typography.h2,
    },
    fontWeight: 700,
    lineHeight: 1.2,
    textAlign: { xs: "center", md: "start" },
  },
  itemImage: {
    backgroundPosition: "center top !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    borderColor: theme.palette.grays[400],
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    height: "100%",
    width: "100%",
  },
  itemImageContainer: {
    borderColor: theme.palette.grays[300],
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    height: 150,
    overflow: "hidden",
    width: 150,
  },
  itemText: {
    color: theme.palette.grays[700],
    fontSize: {
      xs: theme.typography.h5,
      md: theme.typography.h4,
    },
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: { xs: "center", md: "start" },
  },
};
