import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import { theme } from "../../utils/theme";

import { styles } from "./style";

const Header = ({ center, color, data, decorationPos, headerRef }) => {
  return (
    <Stack
      ref={headerRef}
      spacing={4}
      sx={{
        ...styles.headerRoot,
        alignItems: center ? "center" : "start",
        textAlign: center ? "center" : "start",
      }}
    >
      {/* {decorationPos === "top" && (
        <>
          <Box
            sx={{
              ...styles.decoration,
              backgroundColor:
                color === "white"
                  ? theme.palette.grays.white
                  : theme.palette.primary.main,
            }}
          />

          <Box sx={styles.margin} />
          <Box sx={styles.margin} />
        </>
      )} */}
      {data.subheader && (
        <Typography
          sx={{
            ...styles.subheader,
            color:
              color === "white"
                ? theme.palette.primary.main
                : // ? theme.palette.grays.white
                  theme.palette.primary.main,
          }}
        >
          {data.subheader}
        </Typography>
      )}

      <Box sx={styles.header}>
        {/* {decorationPos === "left" && (
          <Box
            sx={{
              ...styles.decoration,
              backgroundColor:
                color === "white"
                  ? theme.palette.grays.white
                  : theme.palette.primary.main,
            }}
          />
        )} */}

        <Typography
          sx={{
            ...styles.headerText,
            color:
              color === "white"
                ? theme.palette.grays.white
                : theme.palette.secondary.main,
          }}
        >
          {data.header}
        </Typography>

        {/* {decorationPos === "right" && (
          <Box
            sx={{
              ...styles.decoration,
              backgroundColor:
                color === "white"
                  ? theme.palette.grays.white
                  : theme.palette.primary.main,
            }}
          />
        )} */}
      </Box>

      {/* {decorationPos === "bottom" && (
        <>
          <Box sx={styles.margin} />
          <Box sx={styles.margin} />

          <Box
            sx={{
              ...styles.decoration,
              backgroundColor:
                color === "white"
                  ? theme.palette.grays.white
                  : theme.palette.primary.main,
            }}
          />
        </>
      )} */}
    </Stack>
  );
};

export default Header;
