import { Box, ClickAwayListener, Paper, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { styles } from "./styles";

const NavProjectSelect = ({ handleCloseMenu, items, open }) => {
  const { t } = useTranslation();

  return (
    open && (
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <Box sx={styles.selectMenuContainer}>
          <Paper sx={styles.selectMenu}>
            {items.map(([key, value], index) => {
              return (
                <Link
                  key={index}
                  rel={value.EXTERNAL_PATH ? "noopener noreferrer" : ""}
                  style={{ textDecoration: "none", width: "100%" }}
                  target={value.EXTERNAL_PATH ? "_blank" : ""}
                  to={value.EXTERNAL_PATH ? value.EXTERNAL_PATH : value.PATH}
                >
                  <Stack spacing={0} sx={styles.selectOption}>
                    <Box sx={styles.selectOptionHeader}>{value.LABEL}</Box>
                    <Box sx={styles.selectOptionBody}>
                      {t(value.DESCRIPTION)}
                    </Box>
                  </Stack>
                </Link>
              );
            })}
          </Paper>
        </Box>
      </ClickAwayListener>
    )
  );
};

export default NavProjectSelect;
