import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";
import { TbPoint } from "react-icons/tb";
import { Link } from "react-router-dom";

import Logo from "../../components/Logo";

import useContent from "../../hooks/useContent";
import { NAV_OPTIONS } from "../../utils/optionUtils";
import { theme } from "../../utils/theme";

import { styles } from "./style";

const Footer = () => {
  const content = useContent();
  const { t } = useTranslation();

  const renderOptions = (key, value) => {
    if (value.ITEMS.length > 0) {
      return (
        <Box key={key} sx={styles.footerColumn}>
          <Typography sx={styles.textLinkDisabled}>{t(value.LABEL)}</Typography>

          {value.ITEMS.map(([key, value], index) => {
            return (
              <Link
                key={index}
                // rel="noopener noreferrer"
                style={styles.textLinkActive}
                // target="_blank"
                to={value.PATH}
              >
                <TbPoint
                  size={"0.8em"}
                  style={{
                    color: theme.palette.primary.main,
                    marginRight: 4,
                  }}
                />
                {t(value.LABEL)}

                <HiArrowTopRightOnSquare
                  size={"0.8em"}
                  style={{
                    marginLeft: 4,
                  }}
                />
              </Link>
            );
          })}
        </Box>
      );
    } else {
      return (
        <Link key={key} to={value.PATH} style={{ textDecoration: "none" }}>
          <Typography sx={styles.textLinkActive}>{t(value.LABEL)}</Typography>
        </Link>
      );
    }
  };

  return (
    <Box sx={styles.footerRoot}>
      <Container maxWidth="lg">
        <Stack spacing={6}>
          <Logo />

          <Grid container direction="row" spacing={2} sx={styles.footerRow}>
            <Grid item xs={12} md={8}>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textLabel}>
                  {content.footer.address.label}
                </Typography>

                <Typography sx={styles.textValue}>
                  {content.footer.address.content}
                </Typography>
              </Box>

              <Box sx={styles.textContainer}>
                <Typography sx={styles.textLabel}>
                  {content.footer.email.label}
                </Typography>

                <Link
                  to={`mailto:${content.footer.email}`}
                  style={styles.textLinkActive}
                >
                  {content.footer.email.content}
                </Link>
              </Box>
            </Grid>

            <Grid item xs={12} md={2}>
              {Array.from(NAV_OPTIONS)
                .slice(0, 2)
                .map(([key, value]) => renderOptions(key, value))}
            </Grid>

            <Grid item xs={12} md={2}>
              {Array.from(NAV_OPTIONS)
                .slice(2, 4)
                .map(([key, value]) => renderOptions(key, value))}
            </Grid>

            {/* <Grid item xs={12} md={2}>
              {Array.from(NAV_OPTIONS)
                .slice(0, 1)
                .map(([key, value]) => renderOptions(key, value))}
            </Grid>

            <Grid item xs={12} md={2}>
              {Array.from(NAV_OPTIONS)
                .slice(1, 2)
                .map(([key, value]) => renderOptions(key, value))}
            </Grid>

            <Grid item xs={12} md={2}>
              {Array.from(NAV_OPTIONS)
                .slice(2, 5)
                .map(([key, value]) => renderOptions(key, value))}
            </Grid> */}
          </Grid>

          <Grid container direction="row" spacing={0} sx={styles.footerRow}>
            <Grid item xs={12} md={12}>
              <Divider sx={styles.footerDivider} />
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={0} sx={styles.footerRow}>
            <Grid item xs={12} md={12}>
              <Typography sx={styles.copyrightText}>
                {content.footer.copyright}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
