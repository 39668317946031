import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useRef, useState } from "react";

import Header from "../Header";
import OverviewItem from "../OverviewItem";
import SectionLayout from "../../layouts/SectionLayout";
import SlideTransition from "../SlideTransition";

import useContent from "../../hooks/useContent";
import useInView from "../../hooks/useInView";
import { MAIN_ROUTES } from "../../utils/routes";
import { appBar } from "../../utils/theme";

import { styles } from "./style";

const Overview = () => {
  const content = useContent();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [setRef, visible] = useInView({
    threshold: 0.1,
  });
  const headerRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <SectionLayout id={MAIN_ROUTES.get("OVERVIEW").ID}>
      <Stack
        alignItems={"start"}
        justifyContent={"start"}
        ref={setRef}
        spacing={0}
        sx={styles.root}
      >
        <Box ref={headerRef} sx={styles.headerContainer}>
          {/* <SlideTransition appear={visible} timeout={800}> */}
          <Stack spacing={4}>
            <Header
              center={matches ? false : true}
              data={content.overview}
              decorationPos={"bottom"}
            />

            <Typography sx={styles.bodyText}>
              {content.overview.body}
            </Typography>
          </Stack>
          {/* </SlideTransition> */}
        </Box>

        <Stack
          alignItems={"start"}
          justifyContent={"start"}
          direction={"row"}
          spacing={6}
          sx={styles.splitLayout}
        >
          <Stack spacing={3} sx={styles.splitLeftColumn}>
            {content.overview.items.map((item, idx) => {
              return (
                <Box key={idx}>
                  <OverviewItem
                    data={item}
                    index={idx}
                    setCurrentIndex={setCurrentIndex}
                  />
                </Box>
              );
            })}
          </Stack>

          {matches && (
            <Stack
              sx={{
                ...styles.splitRightColumn,
                top: `calc(${appBar.height}px + ${headerRef?.current?.clientHeight}px)`,
              }}
            >
              <img
                alt={content.overview.items[currentIndex].header}
                src={content.overview.items[currentIndex].image}
                style={styles.image}
              />
            </Stack>
          )}
        </Stack>
        {/* <Box sx={styles.backgroundOverlay} /> */}
      </Stack>
      {/* <Box sx={styles.background} /> */}
    </SectionLayout>
  );
};

export default Overview;
