import { appBar, glass, theme } from "../../../utils/theme";

const nav = {
  alignItems: "center",
  backdropFilter: "none",
  borderBottomColor: theme.palette.grays[300],
  borderBottomStyle: "solid",
  borderBottomWidth: 0,
  display: "flex",
};

export const styles = {
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: theme.spacing(1),
  },
  navContained: {
    ...nav,
    background: theme.palette.grays.white,
    backdropFilter: glass.backdropFilter,
    border: "1px solid rgba(0, 0, 0, 0.08)",
    color: theme.palette.secondary.main,
  },
  navDefault: {
    ...nav,
    background: theme.palette.grays.white,
    backdropFilter: glass.backdropFilter,
    border: "1px solid rgba(0, 0, 0, 0.08)",
    color: theme.palette.secondary.main,
  },
  toolbar: {
    height: appBar.height,
    pb: 0,
    pl: `0 !important`,
    pr: `0 !important`,
    pt: 0,
    position: "relative",
  },
};
