import { body, theme } from "../../utils/theme";

import circle_1 from "../../assets/background/circle_1.png";
import circle_2 from "../../assets/background/circle_2.png";
import circle_3 from "../../assets/background/circle_3.png";

const backgroundOverlayBefore = {
  backgroundColor: "rgba(15, 143, 247,0.9)",
  backgroundPosition: "right top !important",
  backgroundRepeat: "no-repeat !important",
  backgroundSize: "contain !important",
  height: 36,
  position: "absolute",
  zIndex: -1,
};

const backgroundOverlayAfter = {
  backgroundColor: "rgba(23, 78, 122,0.9)",
  backgroundPosition: "left bottom !important",
  backgroundRepeat: "no-repeat !important",
  backgroundSize: "contain !important",
  height: 36,
  position: "absolute",
  zIndex: -1,
};

export const styles = {
  background: {
    // background:
    //   "linear-gradient(179.75deg, #F5FAFD 0.28%, rgba(245, 250, 253, 0) 28.3%)",
    // backgroundColor: theme.palette.primary.light,
    borderRadius: "0 00px 0 0",
    height: "100%",
    right: { xs: -132, sm: -80, md: -30 },
    top: { xs: 0, sm: 0 },
    // transform: "rotate(5deg)",
    position: "absolute",
    width: "208%",
    zIndex: -1,
  },
  backgroundDeco1: {
    background: `url(${circle_1})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    height: 900,
    position: "absolute",
    width: 900,
  },
  backgroundDeco2: {
    background: `url(${circle_2})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    height: 850,
    position: "absolute",
    width: 850,
  },
  backgroundDeco3: {
    background: `url(${circle_3})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    height: 500,
    position: "absolute",
    width: 500,
  },
  backgroundDecoContainer: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: { xs: "-37%", md: -400 },
    width: "100%",
    zIndex: -1,
  },
  backgroundOverlayTopAfter: {
    ...backgroundOverlayAfter,
    right: { xs: 130, md: 160 },
    top: -48,
    width: { xs: 140, md: 232 },
  },
  backgroundOverlayTopBefore: {
    ...backgroundOverlayBefore,
    right: { xs: 100, md: -200 },
    top: -24,
    width: { xs: "30%", md: "24%" },
  },
  body: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    pr: { xs: 8, md: 0 },
    zIndex: 1,
  },
  bodyText: {
    color: body.color,
    fontSize: body.fontSize,
    fontWeight: body.fontWeight,
    mb: { xs: 4, md: 0 },
    mt: { xs: 6, md: 6 },
    textAlign: { xs: "center", md: "center" },
    textTransform: "none",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  root: {
    mb: 0,
    // mt: { xs: 12, md: 24 },
    position: "relative",
  },
};
