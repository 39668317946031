import { footer, theme } from "../../utils/theme";

const textLink = {
  color: theme.palette.grays[900],
  fontSize: theme.typography.fontSize * 0.8,
  textDecoration: "none",
  width: "fit-content",
};

export const styles = {
  addressText: {
    color: theme.palette.grays[900],
    fontSize: theme.typography.fontSize * 1.4,
  },
  bulletSeperator: {
    backgroundColor: theme.palette.grays.white,
    borderRadius: 50,
    minHeight: 3,
    ml: { xs: 2, md: 2 },
    mr: { xs: 2, md: 2 },
    mt: { xs: 2, md: 2.4 },
    minWidth: 3,
  },
  copyrightText: {
    color: theme.palette.grays[900],
    fontSize: theme.typography.fontSize * 0.6,
    textAlign: { xs: "left", md: "right" },
  },
  footerColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  footerDivider: {
    borderColor: theme.palette.grays[400],
  },
  footerHead: {
    alignItems: { xs: "flex-start", sm: "center" },
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: { xs: "center", sm: "flex-start" },
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  footerRoot: {
    alignItems: "center",
    backgroundColor: theme.palette.background.muted,
    display: "flex",
    height: footer.height,
    justifyContent: "center",
    pb: { xs: 2, md: 2 },
    pt: { xs: 8, md: 12 },
    position: "static",
    width: "100%",
  },
  footerRow: {
    alignItems: "start",
    display: "flex",
    justifyContent: "space-between",
    mb: { xs: 2, md: 2 },
    mt: { xs: 2, md: 2 },
    width: "100%",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: theme.spacing(1),
  },
  socialMediaContainer: {
    display: "flex",
    mb: { xs: 2, sm: 0 },
    justifyContent: "space-between",
    width: { xs: 80, md: 100 },
  },
  socialMediaIcon: {
    color: theme.palette.grays[900],
    mr: 0,
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      color: theme.palette.grays[400],
    },
  },
  textContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    gap: { xs: 1, sm: 4 },
    mb: 4,
    width: "100%",
  },
  textLabel: {
    color: theme.palette.grays[900],
    fontSize: theme.typography.fontSize * 0.8,
    fontWeight: 600,
    minWidth: 40,
    textTransform: "capitalize",
  },
  textValue: {
    color: theme.palette.grays[900],
    fontSize: theme.typography.fontSize * 0.8,
    maxWidth: 400,
  },
  textLinkActive: {
    ...textLink,
    cursor: "pointer",
    textDecoration: "none",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.grays[400],
    },
  },
  textLinkDisabled: {
    ...textLink,
    textDecoration: "none",
  },
};
