import { appBar, theme } from "../../utils/theme";

import bg_2 from "../../assets/background/bg_2.svg";

export const styles = {
  background: {
    // background:
    //   "linear-gradient(179.75deg, #F5FAFD 0.28%, rgba(245, 250, 253, 0) 28.3%)",
    // backgroundColor: theme.palette.grays.white,
    // backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: "0 00px 0 0",
    height: "100%",
    right: { xs: -30, sm: -30 },
    top: { xs: 0, sm: 0 },
    transform: "rotate(5deg)",
    position: "absolute",
    width: "208%",
    zIndex: -1,
  },
  backgroundOverlay: {
    background: `url(${bg_2})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    height: { xs: 800, sm: "80%" },
    left: { xs: 0, sm: "-33%" },
    bottom: { xs: 0, sm: "-10%" },
    position: "absolute",
    width: { xs: 800, sm: "100%" },
    zIndex: 0,
  },
  body: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    mt: 10,
    position: "relative",
    zIndex: 1,
  },
  bodyText: {
    color: theme.palette.grays[700],
    fontSize: { xs: theme.typography.body, md: theme.typography.h4 },
    fontWeight: 400,
    mb: { xs: 4, md: 0 },
    mt: { xs: 6, md: 6 },
    textAlign: { xs: "center", md: "start" },
    textTransform: "none",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  headerContainer: {
    background: `linear-gradient(180deg,${theme.palette.grays.white} 90%,hsla(0,0%,100%,0))`,
    pb: 8,
    pt: 4,
    position: "sticky",
    top: appBar.height,
    width: "100%",
    zIndex: 1,
  },
  image: {
    borderColor: theme.palette.grays[400],
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    width: 720,
  },
  splitLayout: {
    height: "100%",
    position: "relative",
    width: "100%",
  },
  splitLeftColumn: {
    backgroundColor: theme.palette.grays.white,
    height: "100%",
  },
  splitRightColumn: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    position: "sticky",
    height: "54vh",
    zIndex: 0,
  },
};
