import { appBar, theme } from "../../utils/theme";

const HEIGHT = `calc(800px + ${appBar.height})`;

export const styles = {
  button: {
    backgroundColor: theme.palette.grays.white,
    color: theme.palette.secondary.main,
    overflow: "hidden",
    transition: "all 0.5s ease-in-out",
    width: { xs: "100%", md: "fit-content" },
    "&:hover": {
      backgroundColor: theme.palette.grays[300],
    },
  },
  buttonSecondary: {
    gap: 1.3,
    width: { xs: "100%", md: "fit-content" },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  heroAction: {
    alignItems: "center",
    display: "flex",
    flexDirection: { xs: "column", sm: "row", md: "row" },
    gap: { xs: 4, sm: 5 },
    justifyContent: "center",
    width: "100%",
  },
  heroBackground: {
    backgroundPosition: "left top",
    backgroundRepeat: { xs: "no-repeat", md: "no-repeat" },
    backgroundSize: { xs: "cover", md: "cover" },
    height: "100%",
    top: -4,
    position: "absolute",
    width: "100%",
    zIndex: 0,
  },
  heroBody: {
    alignItems: { xs: "center", md: "start" },
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    width: { xs: "100%", md: "100%" },
  },
  heroContent: {
    alignItems: { xs: "center", md: "start" },
    justifyContent: "center",
    position: "absolute",
    width: { xs: "88vw", md: 700 },
  },
  heroContainer: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "relative",
    width: "100%",
  },
  heroForeground: {
    alignItems: "center",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: { xs: "column-reverse", md: "row" },
    height: { xs: "100vh", sm: "60vh", md: "100vh" },
    justifyContent: "center",
    maxHeight: { md: HEIGHT },
    minHeight: { sm: 400 },
    overflow: "hidden",
    position: "relative",
    zIndex: 2,
  },
  heroHeader: {
    color: theme.palette.grays.white,
    fontSize: {
      xs: theme.typography.h1,
      md: theme.typography.fontSize * 4.25,
    },
    fontWeight: 600,
    letterSpacing: 0.9,
    lineHeight: 1,
    textAlign: "center",
    textTransform: "capitalize",
  },
  heroHeaderContainer: {
    alignItems: { xs: "center", md: "start" },
  },
  heroImage: {
    position: "absolute",
    right: "-8%",
    top: "12%",
    zIndex: 0,
  },
  heroImageContainer: {
    height: { xs: "90%", md: "100%" },
    position: "relative",
    width: { xs: "100%", md: "50%" },
  },
  heroSlogan: {
    color: theme.palette.grays.white,
    fontSize: {
      xs: theme.typography.fontSize * 0.75,
      md: theme.typography.fontSize * 1.5,
    },
    fontWeight: 700,
    letterSpacing: 0.9,
    lineHeight: 1.2,
    mb: 0,
    position: { xs: "relative", md: "absolute" },
    left: { xs: 0, md: 48 },
    textAlign: "center",
    textTransform: "uppercase",
    whiteSpace: "pre-line",
    width: { xs: "100%", md: 256 },
  },
  heroSloganContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    height: { xs: "100%", md: 280 },
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  heroSubSlogan: {
    color: theme.palette.grays.white,
    fontSize: {
      xs: theme.typography.fontSize * 2,
      md: theme.typography.fontSize * 3.75,
    },
    fontWeight: 700,
    letterSpacing: 0.9,
    lineHeight: { xs: 1.2, md: 1.2 },
    position: { xs: "relative", md: "absolute" },
    right: { xs: 0, md: 72 },
    textAlign: "center",
    textTransform: "uppercase",
    whiteSpace: "pre-line",
    width: { xs: "100%", md: 300 },
  },
  heroSubheader: {
    color: theme.palette.grays.white,
    fontSize: {
      xs: theme.typography.body,
      md: theme.typography.body,
    },
    fontWeight: 600,
    letterSpacing: 0.9,
    lineHeight: 1,
    textAlign: "center",
    textTransform: "capitalize",
  },
  heroText: {
    color: theme.palette.grays[300],
    fontSize: {
      xs: theme.typography.body3,
      lg: theme.typography.h4,
    },
    fontWeight: 500,
    letterSpacing: 0.9,
    textAlign: { xs: "center", md: "start" },
  },
  heroWave: {
    height: "8%",
    bottom: -1,
    position: "absolute",
    width: "100%",
  },
  margin: {
    display: "flex",
    margin: theme.spacing(1),
  },
  video: {
    height: "100%",
    width: "100%",
  },
  videoContainer: {
    height: { xs: "100vh", sm: "100vh", md: "100vh" },
    maxHeight: { md: HEIGHT },
    minHeight: { sm: 400 },
    overflow: "hidden",
    position: "absolute",
    // top: `-${appBar.height}`,
    width: "100vw",
  },
  videoPosition: {
    filter: "brightness(0.6) contrast(120%) hue-rotate(32deg)",
    position: "absolute",
    right: { xs: "-120%", sm: "-20%", md: "-8%", lg: 0 },
    width: { xs: "360%", sm: "155%", md: "130%", lg: "100%" },
  },
};
