import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowDownSLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import NavLanguageSelect from "../NavLanguageSelect";
import NavProjectSelect from "../NavProjectSelect";

import { NAV_OPTIONS } from "../../../utils/optionUtils";

import { styles } from "./style";

const NavList = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [mouseOverItem, setMouseOverItem] = useState(undefined);

  useEffect(() => {
    setMouseOverItem(undefined);
  }, [location]);

  const handleCloseMenu = () => {
    setMouseOverItem(undefined);
  };

  return (
    <Box sx={styles.navListContainer}>
      <Box sx={styles.grow} />

      {Array.from(NAV_OPTIONS).map(([key, value], index) => {
        const isMouseOverItem = mouseOverItem === index;

        if (value.ITEMS && value.ITEMS.length > 0) {
          return (
            <Box
              key={key}
              onMouseEnter={() => setMouseOverItem(index)}
              onMouseLeave={() => setMouseOverItem(undefined)}
              sx={
                isMouseOverItem
                  ? styles.navOptionActive
                  : styles.navOptionInactive
              }
            >
              <Typography sx={styles.navText}>{t(value.LABEL)}</Typography>
              <RiArrowDownSLine
                size={"0.8em"}
                style={{
                  transform: isMouseOverItem
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  // transition: "all 0.2s ease-in-out",
                }}
              />

              <NavProjectSelect
                handleCloseMenu={handleCloseMenu}
                items={value.ITEMS}
                open={isMouseOverItem}
              />
            </Box>
          );
        } else {
          return (
            <Link
              key={key}
              onMouseEnter={() => setMouseOverItem(index)}
              onMouseLeave={() => setMouseOverItem(undefined)}
              rel={value.EXTERNAL ? "noopener noreferrer" : ""}
              target={value.EXTERNAL ? "_blank" : ""}
              to={value.PATH}
              style={
                isMouseOverItem
                  ? styles.navOptionActive
                  : styles.navOptionInactive
              }
            >
              <Typography sx={styles.navText}>{t(value.LABEL)}</Typography>
            </Link>
          );
        }
      })}

      <Box sx={styles.grow} />

      <Divider orientation="vertical" sx={{ height: "64%" }} />

      <Link
        style={{
          textDecoration: "none",
        }}
        to={"https://www.comp.nus.edu.sg/~dbsystem/"}
        rel={"noopener noreferrer"}
        target={"_blank"}
      >
        <Box sx={styles.navOptionActive}>
          <Typography sx={styles.navText}>DB System</Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default NavList;
