import { useCallback, useRef, useState } from "react";

import useEventListener from "./useEventListener";

const useScrollPosition = () => {
  const ref = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const callback = useCallback(() => {
    if (ref.current) {
      const position = Math.abs(
        ref.current.getBoundingClientRect().top - ref.current.offsetTop
      );

      setScrollPosition(position);
    }
  }, []);

  useEventListener("scroll", callback, true);

  return { ref, scrollPosition };
};

export default useScrollPosition;
