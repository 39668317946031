import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Alert from "../../components/Alert";
import ContactSection from "../../components/ContactSection";
import Features from "../../components/Features";
import Hero from "../../components/Hero";
import Overview from "../../components/Overview";
import Team from "../../components/Team";

const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      handleScrollToSection(location.hash);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const handleScrollToSection = (hash) => {
    document.querySelector(hash)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Box>
      {/* <Alert /> */}
      <Hero />

      <Features />
      <Overview />
      {/* <Team /> */}

      <ContactSection />
    </Box>
  );
};

export default HomePage;
