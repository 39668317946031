import gangchen from "../assets/team/gangchen.png";
import shen_yanyan from "../assets/team/shen_yanyan.png";
import zhang_bingxue from "../assets/team/zhang_bingxue.jpg";
import wuyuncheng from "../assets/team/wuyuncheng.jpg";
import zhangmeihui from "../assets/team/zhangmeihui.png";

const collaborators = [
  {
    image: gangchen,
    header: "Chen Gang",
    content: "Professor, Zhejiang University",
    action: "",
    link: "",
    position: "professor",
  },
  {
    image: zhangmeihui,
    header: "Zhang Meihui",
    content: "Professor, Beijing Institute of Technology",
    action: "",
    link: "",
    position: "professor",
  },
  {
    image: wuyuncheng,
    header: "Wu Yuncheng",
    content: "Associate Professor, Renmin University of China",
    action: "",
    link: "",
    position: "professor",
  },
  {
    image: shen_yanyan,
    header: "Shen Yanyan",
    content: "Associate Professor, Shanghai Jiao Tong University",
    action: "",
    link: "",
    position: "externalCollaborator",
  },
  {
    image: zhang_bingxue,
    header: "Zhang Bingxue",
    content:
      "Associate Professor, University of Shanghai for Science and Technology",
    action: "",
    link: "",
    position: "externalCollaborator",
  },
];

export default collaborators;
