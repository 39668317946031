import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";

import useContent from "../../hooks/useContent";

import { styles } from "./styles";

const Loader = ({ stateless }) => {
  const content = useContent();

  const loaderContainer = (
    <Box sx={styles.loaderContainer}>
      <Typography sx={styles.text}>{content.loading.content}</Typography>

      <Box sx={{ width: 180 }}>
        <LinearProgress />
      </Box>
    </Box>
  );

  return <>{loaderContainer}</>;
};

export default Loader;
