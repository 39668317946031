import { theme } from "../../utils/theme";

import contact_bg from "../../assets/background/contact_bg.jpg";

export const styles = {
  background: {
    background: theme.palette.background.gradient,
    height: "100%",
    right: { xs: 0, sm: 0 },
    top: { xs: 0, sm: 0 },
    position: "absolute",
    width: "100%",
    zIndex: -1,
  },
  backgroundOverlay: {
    background: `url(${contact_bg})`,
    backgroundPosition: "center top !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    bottom: 0,
    filter: "brightness(1) opacity(0.3)",
    height: "100%",
    left: { xs: 0, sm: 0 },
    position: "absolute",
    width: "150%",
    zIndex: 0,
  },
  body: {
    alignItems: "center",
    display: "flex",
    justifyContent: "start",
    position: "relative",
  },
  bodyText: {
    color: theme.palette.grays[200],
    fontSize: { xs: theme.typography.body, md: theme.typography.h4 },
    fontWeight: 300,
    textAlign: { xs: "center", md: "center" },
    textTransform: "none",
  },
  button: {
    backgroundColor: theme.palette.grays.white,
    color: theme.palette.secondary.main,
    transition: "all 0.5s ease-in-out",
    width: { xs: "100%", md: "fit-content" },
    "&:hover": {
      backgroundColor: theme.palette.grays[300],
    },
  },
  contactRoot: {
    alignItems: { xs: "center", md: "center" },
    mb: { xs: -6, md: -12 },
    mt: { xs: -2, md: -12 },
    zIndex: 1,
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
};
