import { body, theme } from "../../utils/theme";

import bg_2 from "../../assets/background/bg_2.svg";
import projects_deco_1 from "../../assets/background/projects_deco_1.png";
import projects_deco_2 from "../../assets/background/projects_deco_2.png";

export const styles = {
  background: {
    backgroundColor: theme.palette.primary.light,
    // backgroundImage: "linear-gradient(to bottom, rgba(255,255,255,0.2), rgba(227, 255, 231,0.4) 30%, rgba(217, 231, 255, 1))",
    height: "100%",
    right: { xs: 0, sm: 0 },
    top: { xs: -42, sm: -42 },
    position: "absolute",
    width: "100%",
    zIndex: -2,
  },
  backgroundOverlay: {
    background: `url(${bg_2})`,
    backgroundPosition: "left top !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    filter: "brightness(0.9) contrast(1) ",
    height: "80%",
    left: { xs: 0, sm: 0 },
    top: -20,
    position: "absolute",
    width: { xs: 600, sm: "100%" },
    zIndex: -1,
  },
  backgroundOverlayAfter: {
    background: `url(${bg_2})`,
    // background: `url(${projects_deco_1})`,
    backgroundPosition: "right bottom !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    bottom: { xs: "2%", md: "-5%" },
    filter: "brightness(0.9) contrast(1) ",
    // filter: "brightness(1.01) contrast(1) ",
    height: "80%",
    right: 0,
    position: "absolute",
    width: { xs: 600, md: "100%" },
    zIndex: -2,
  },
  backgroundOverlayBefore: {
    background: `url(${projects_deco_2})`,
    backgroundPosition: "left top !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    filter: "brightness(1.01) contrast(1) ",
    height: "70%",
    left: 0,
    top: { xs: "12%", md: "20%" },
    position: "absolute",
    width: { xs: 600, md: "100%" },
    zIndex: -1,
  },
  body: {
    alignItems: "start",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  bodyText: {
    color: theme.palette.grays[700],
    fontSize: { xs: theme.typography.body, md: theme.typography.h4 },
    fontWeight: 400,
    mb: { xs: 4, md: 0 },
    mt: { xs: 6, md: 6 },
    textAlign: { xs: "center", md: "center" },
    textTransform: "none",
  },
  footerText: {
    color: theme.palette.accent.main,
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1,
    },
    mt: { xs: 4, md: 4 },
    textAlign: { xs: "end", md: "end" },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  item: {
    minHeight: { xs: 280, sm: 280, md: 320 },
    pb: 8,
    pl: 7,
    pr: 5,
    pt: 8,
  },
  itemBody: {
    color: theme.palette.grays[700],
    fontSize: {
      xs: theme.typography.body,
      md: theme.typography.body,
    },
    textAlign: { xs: "left", md: "left" },
  },
  itemContent: {
    alignItems: { xs: "start", md: "start" },
    display: "flex",
    flexDirection: { xs: "column", md: "column" },
    gap: 3,
    justifyContent: { xs: "start", md: "start" },
  },
  itemHeader: {
    color: theme.palette.secondary.main,
    fontSize: {
      xs: theme.typography.body,
      md: theme.typography.h4,
    },
    fontWeight: 700,
    letterSpacing: 1.2,
    lineHeight: 1.1,
  },
  itemImage: {
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    filter: "brightness(5) grayscale(1)",
    height: "100%",
    width: "100%",
  },
  itemImageContainer: {
    background: theme.palette.background.gradient,
    borderRadius: 2,
    boxShadow: "0 0 24px rgba(37,43,129,.04), 0 44px 74px rgba(37,43,129,.06)",
    height: 68,
    p: 4,
    width: 68,
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  root: {
    mb: -12,
    mt: { xs: 0, md: -12 },
  },
};
