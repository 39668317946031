import { theme } from "../../../utils/theme";

const navOption = {
  alignItems: "center",
  backgroundColor: "transparent",
  borderRadius: 50,
  color: "inherit",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  gap: 1,
  height: "100%",
  justifyContent: "center",
  minWidth: 50,
  paddingBottom: "8px",
  paddingTop: "8px",
  position: "relative",
  textDecoration: "none",
  transition: "all 0.3s ease-in-out",
  width: "fit-content",
};

export const styles = {
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  indicator: {
    bottom: 0,
    height: 3,
    position: "absolute",
    width: "100%",
    zIndex: 0,
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  navListContainer: {
    alignItems: "center",
    display: { md: "flex", xs: "none" },
    flex: 1,
    flexDirection: "row",
    gap: 7,
    height: "100%",
    justifyContent: "end",
  },
  navOptionActive: {
    ...navOption,
    color: theme.palette.primary.dark,
  },
  navOptionInactive: {
    ...navOption,
  },
  navText: {
    fontSize: {
      md: theme.typography.fontSize * 0.7,
      lg: theme.typography.fontSize * 0.8,
    },
    fontWeight: 500,
    letterSpacing: 0.5,
    lineHeight: 1,
  },
};
