import { useTranslation } from "react-i18next";

import { RiFacebookCircleFill, RiLinkedinBoxFill } from "react-icons/ri";

import features_1 from "../assets/images/features_1.png";
import features_2 from "../assets/images/features_2.png";
import features_3 from "../assets/images/features_3.png";
import features_4 from "../assets/images/features_4.png";

import overview_1 from "../assets/overview/overview_1.png";
import overview_2 from "../assets/overview/overview_2.png";
import overview_3 from "../assets/overview/overview_3.png";
import overview_4 from "../assets/overview/overview_4.png";
import overview_5 from "../assets/overview/overview_5.png";

import partners_foodlg from "../assets/partners/FOODLG.png";
import partners_medilot from "../assets/partners/MEDILOT.svg";
import partners_mzh from "../assets/partners/MZH.png";
import partners_nus from "../assets/partners/nus_logo.jpg";
import partners_nusdbsys from "../assets/partners/nusdbsystem_logo.png";
import partners_nuh from "../assets/partners/nuh_logo.png";
import partners_nuhs from "../assets/partners/NUHS.png";
import partners_sbip from "../assets/partners/sbip_logo.png";
import partners_sgh from "../assets/partners/SGH.png";
import partners_shentilium from "../assets/partners/Shentilium.png";

import blog from "../content/blog";
import collaborators from "../content/collaborators";
import events from "../content/events";
import publications from "../content/publications";
import team from "../content/team";

const useContent = () => {
  const { t } = useTranslation();

  const content = {
    about: {
      header: t("ABOUT_HEADER"),
      image: "",
      subheader: t("ABOUT_SUBHEADER"),
      content: t("ABOUT_CONTENT"),
    },

    blog: {
      header: t("BLOG_HEADER"),
      subheader: t("BLOG_SUBHEADER"),
      body: t("BLOG_BODY"),
      items: blog,
      null: t("BLOG_NULL"),
    },

    contact: {
      header: t("CONTACT_HEADER"),
      subheader: t("CONTACT_SUBHEADER"),
      content: t("CONTACT_CONTENT"),
      action: t("CONTACT_ACTION"),
    },

    contactForm: {
      action: t("CONTACT_FORM_ACTION"),
      body: t("CONTACT_FORM_BODY"),
      header: t("CONTACT_FORM_HEADER"),
      options: [
        t("CONTACT_FORM_OPTION_ITEM_1"),
        t("CONTACT_FORM_OPTION_ITEM_2"),
        t("CONTACT_FORM_OPTION_ITEM_3"),
        t("CONTACT_FORM_OPTION_ITEM_4"),
      ],
      optionLabel: t("CONTACT_FORM_OPTION_LABEL"),
      placeholders: {
        email: t("CONTACT_FORM_PLACEHOLDER_EMAIL"),
        lastName: t("CONTACT_FORM_PLACEHOLDER_LAST_NAME"),
        message: t("CONTACT_FORM_PLACEHOLDER_MESSAGE"),
        name: t("CONTACT_FORM_PLACEHOLDER_NAME"),
        phone: t("CONTACT_FORM_PLACEHOLDER_PHONE"),
        purpose: t("CONTACT_FORM_PLACEHOLDER_PURPOSE"),
      },
      subheader: t("CONTACT_FORM_SUBHEADER"),
    },

    error: {
      allRequired: t("ERROR_ALL_REQUIRED"),
      validEmail: t("ERROR_VALID_EMAIL"),
    },

    events: {
      header: t("EVENTS_HEADER"),
      subheader: t("EVENTS_SUBHEADER"),
      body: t("EVENTS_BODY"),
      items: events,
      null: t("EVENTS_NULL"),
    },

    external_collaborators: {
      header: t("TEAM_HEADER_COLLABORATORS"),
      subheader: t("TEAM_SUBHEADER_COLLABORATORS"),
      members: collaborators,
    },

    features: {
      header: t("FEATURES_HEADER"),
      subheader: t("FEATURES_SUBHEADER"),
      body: t("FEATURES_BODY"),
      items: [
        {
          image: features_1,
          header: t("FEATURE_ITEM_HEADER_1"),
          content: t("FEATURE_ITEM_CONTENT_1"),
        },
        {
          image: features_2,
          header: t("FEATURE_ITEM_HEADER_2"),
          content: t("FEATURE_ITEM_CONTENT_2"),
        },
        {
          image: features_3,
          header: t("FEATURE_ITEM_HEADER_3"),
          content: t("FEATURE_ITEM_CONTENT_3"),
        },
        {
          image: features_4,
          header: t("FEATURE_ITEM_HEADER_4"),
          content: t("FEATURE_ITEM_CONTENT_4"),
        },
      ],
      action: "",
    },

    footer: {
      socialMedia: [
        // {
        //   icon: <RiTwitterFill size="1.8em" />,
        //   label: "",
        //   link: "",
        // },
        {
          icon: <RiLinkedinBoxFill size="1.8em" />,
          label: "",
          link: "https://www.linkedin.com/",
        },
        {
          icon: <RiFacebookCircleFill size="1.8em" />,
          label: "",
          link: "https://www.facebook.com/",
        },
      ],
      address: {
        label: `${t("FOOTER_ADDRESS_LABEL")}`,
        content: [
          "School of Computing, NUS COM1-01-07, 13 Computing Drive, ",
          "Singapore 117417",
        ],
      },
      email: {
        label: `${t("FOOTER_EMAIL_LABEL")}`,
        content: "dbsystem@comp.nus.edu.sg",
      },
      copyright: `${t(
        "FOOTER_COPYRIGHT_HEAD"
      )} © ${new Date().getFullYear()} NeurDB. ${t("FOOTER_COPYRIGHT_END")}`,
    },

    hero: {
      action: t("HERO_ACTION"),
      content: t("HERO_CONTENT"),
      header: t("HERO_HEADER"),
      subaction: t("HERO_SUBACTION"),
      subheader: t("HERO_SUBHEADER"),
    },

    loading: {
      content: t("LOADING_CONTENT"),
      sending: t("LOADING_SENDING"),
    },

    meta: {
      title: t("WEBPAGE_TITLE"),
      description: t("WEBPAGE_DESCRIPTION"),
    },

    overview: {
      header: t("OVERVIEW_HEADER"),
      subheader: t("OVERVIEW_SUBHEADER"),
      body: t("OVERVIEW_BODY"),
      items: [
        {
          image: overview_1,
          header: t("OVERVIEW_ITEM_HEADER_1"),
          content: t("OVERVIEW_ITEM_CONTENT_1"),
        },
        {
          image: overview_2,
          header: t("OVERVIEW_ITEM_HEADER_2"),
          content: t("OVERVIEW_ITEM_CONTENT_2"),
        },
        {
          image: overview_3,
          header: t("OVERVIEW_ITEM_HEADER_3"),
          content: t("OVERVIEW_ITEM_CONTENT_3"),
        },
        {
          image: overview_4,
          header: t("OVERVIEW_ITEM_HEADER_4"),
          content: t("OVERVIEW_ITEM_CONTENT_4"),
        },
        {
          image: overview_5,
          header: t("OVERVIEW_ITEM_HEADER_5"),
          content: t("OVERVIEW_ITEM_CONTENT_5"),
        },
      ],
    },

    publications: {
      header: t("PUBLICATIONS_HEADER"),
      subheader: t("PUBLICATIONS_SUBHEADER"),
      body: t("PUBLICATIONS_BODY"),
      items: publications,
      action: "",
      null: t("PUBLICATIONS_NULL"),
    },

    partners: {
      header: t("PARTNERS_HEADER"),
      subheader: t("PARTNERS_SUBHEADER"),
      body: t("PARTNERS_BODY"),
      items: [
        // { image: partners_nus, name: "NUS", link: "https://nus.edu.sg/" },
        // {
        //   image: partners_soc,
        //   name: "NUS School of Computing",
        //   link: "https://www.comp.nus.edu.sg/",
        // },
        // {
        //   image: partners_nusdbsys,
        //   name: "NUS DB System",
        //   link: "https://www.comp.nus.edu.sg/~dbsystem/",
        // },
        // { image: partners_sbip, name: "SBIP", link: "https://sbip.sg/" },
        {
          image: partners_medilot,
          name: "Medilot",
          link: "https://www.comp.nus.edu.sg/~dbsystem/",
        },
        {
          image: partners_mzh,
          name: "MZH",
          link: "https://www.comp.nus.edu.sg/~dbsystem/",
        },
        {
          image: partners_nuh,
          name: "NUH",
          link: "https://www.comp.nus.edu.sg/~dbsystem/",
        },
        {
          image: partners_nuhs,
          name: "NUHS",
          link: "https://www.comp.nus.edu.sg/~dbsystem/",
        },
        {
          image: partners_sgh,
          name: "SGH",
          link: "https://www.comp.nus.edu.sg/~dbsystem/",
        },
        {
          image: partners_shentilium,
          name: "Shentilium",
          link: "https://www.comp.nus.edu.sg/~dbsystem/",
        },
        {
          image: partners_foodlg,
          name: "FoodLg",
          link: "https://www.comp.nus.edu.sg/~dbsystem/",
        },
      ],
    },

    team: {
      header: t("TEAM_HEADER"),
      subheader: t("TEAM_SUBHEADER"),
      body: "",
      members: team,
      action: "",
    },
  };

  return content;
};

Object.freeze(useContent);

export default useContent;
