import { theme } from "../../utils/theme";

export const styles = {
  loaderContainer: {
    alignItems: "center",
    backgroundColor: theme.palette.grays["white"],
    cursor: "default",
    display: "flex",
    flexDirection: "column",
    gap: 3,
    height: "100%",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 9999,
  },
  text: {
    fontSize: theme.typography.body2,
    fontWeight: 400,
    letterSpacing: 1.2,
    lineHeight: 1.1,
  },
};
