import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { LANGUAGE_KEY } from "../utils/translationUtil";

import translationEn from "./en/translation.json";
import translationZhCn from "./zh-cn/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      en: ["en"],
      "zh-CN": ["zh_Hans"],
      "zh-TW": ["zh_Hant"],
      default: "en",
    },
    debug: false,
    lng: localStorage.getItem(LANGUAGE_KEY) || "en",

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEn,
      },
      zh_Hans: {
        translations: translationZhCn,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
