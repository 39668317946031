export const RESOURCES_ROUTES = new Map([
  [
    "BLOG_LIST",
    {
      DESCRIPTION: "BLOG_BODY",
      ID: "_blog",
      ITEMS: [],
      LABEL: "Blog",
      PATH: "/blog",
      VALUE: "blog",
    },
  ],
  [
    "EVENTS_LIST",
    {
      DESCRIPTION: "EVENTS_BODY",
      ID: "_events",
      ITEMS: [],
      LABEL: "Events",
      PATH: "/events",
      VALUE: "events",
    },
  ],
]);

export const MAIN_ROUTES = new Map([
  [
    "ABOUT",
    {
      ID: "_about",
      ITEMS: [],
      LABEL: "About Us",
      PATH: "/#_about",
      VALUE: "about",
    },
  ],
  [
    "BLOG",
    {
      ICON: "",
      LABEL: "",
      PATH: "/blog/:blogPath",
    },
  ],
  [
    "CONTACT",
    {
      ID: "_contact",
      ITEMS: [],
      LABEL: "Contact Us",
      PATH: "/contact",
      VALUE: "contact",
    },
  ],
  [
    "EVENT",
    {
      LABEL: "",
      PATH: "/events/:eventPath",
    },
  ],
  [
    "FEATURES",
    {
      ID: "_features",
      ITEMS: [],
      LABEL: "Features",
      PATH: "/features",
      VALUE: "features",
    },
  ],
  [
    "HOME",
    {
      ID: "_home",
      ITEMS: [],
      LABEL: "Home",
      PATH: "/",
      VALUE: "home",
    },
  ],
  [
    "INTRODUCTION",
    {
      ID: "_introduction",
      ITEMS: [],
      LABEL: "Introduction",
      PATH: "/introduction",
      VALUE: "introduction",
    },
  ],
  [
    "OVERVIEW",
    {
      ID: "_overview",
      ITEMS: [],
      LABEL: "Overview",
      PATH: "/#_overview",
      VALUE: "overview",
    },
  ],
  [
    "PARTNERS",
    {
      ID: "_partners",
      ITEMS: [],
      LABEL: "Partners",
      PATH: "/#_partners",
      VALUE: "partners",
    },
  ],
  [
    "PEOPLE",
    {
      ID: "_people",
      ITEMS: [],
      LABEL: "People",
      PATH: "/people",
      VALUE: "people",
    },
  ],
  [
    "PUBLICATIONS",
    {
      ID: "_publications",
      ITEMS: [],
      LABEL: "Publications",
      PATH: "/publications",
      VALUE: "publications",
    },
  ],
  [
    "RESOURCES",
    {
      ID: "_resources",
      ITEMS: Array.from(RESOURCES_ROUTES),
      LABEL: "Resources",
      PATH: "/resources",
      VALUE: "resources",
    },
  ],
  [
    "GITHUB",
    {
      EXTERNAL: true,
      ID: "_github",
      ITEMS: [],
      LABEL: "GitHub",
      PATH: "https://github.com/neurdb/neurdb",
      VALUE: "github",
    },
  ],
]);
