import { theme } from "../../utils/theme";

export const styles = {
  item: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    minHeight: { xs: 260, md: 280 },
    position: "relative",
    transition: "all 0.3s ease-in-out",
  },
  itemAction: {
    alignItems: "center",
    color: theme.palette.primary.main,
    cursor: "pointer",
    display: "flex",
    fontSize: {
      xs: theme.typography.h4,
      md: theme.typography.h4,
    },
    fontWeight: 600,
  },
  itemBody: {
    backgroundColor: theme.palette.grays.white,
    borderRadius: 2,
    boxShadow: "0 0 24px rgba(37,43,129,.04), 0 44px 74px rgba(37,43,129,.06)",
    height: 160,
    pb: 2,
    pt: 2,
    width: 300,
  },
  itemHeader: {
    color: theme.palette.secondary.main,
    fontSize: {
      xs: theme.typography.h4,
      md: theme.typography.h4,
    },
    fontWeight: 700,
    textAlign: "center",
  },
  itemImage: {
    backgroundPosition: "center top !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    height: "100%",
    width: "100%",
  },
  itemImageContainer: {
    borderColor: theme.palette.grays[300],
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    boxShadow: "0 0 24px rgba(37,43,129,.04), 0 44px 74px rgba(37,43,129,.06)",
    height: { xs: 100, md: 120 },
    overflow: "hidden",
    width: { xs: 100, md: 120 },
    zIndex: 1,
  },
  itemText: {
    color: theme.palette.grays[700],
    fontSize: {
      xs: theme.typography.body,
      md: theme.typography.body,
    },
    fontWeight: 400,
    lineHeight: 1.3,
    maxWidth: 220,
    textAlign: "center",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
};
