import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo/logo_main.png";
import logoLight from "../../assets/logo/logo_white.svg";
import logoIcon from "../../assets/logo/logo_icon.png";

import { MAIN_ROUTES } from "../../utils/routes";
import { styles } from "./style";

const Logo = ({ lightTheme }) => {
  return (
    <Link sx={styles.logoContainer} to={MAIN_ROUTES.get("HOME").PATH}>
      <Box
        component="div"
        sx={styles.logo}
        style={
          lightTheme
            ? { backgroundImage: `url(${logoLight})` }
            : { backgroundImage: `url(${logo})` }
        }
      />
      {/* <Box component="div" sx={styles.logo}>
          {lightTheme ? (
            <Image src={logoLight} alt={"logo"} />
          ) : (
            <Image src={logo} alt={"logo"} />
          )}
        </Box> */}

      {/* <Box component="div" sx={styles.logoIcon}>
          <Image src={logoIcon} alt={"logo"} />
        </Box> */}
    </Link>
  );
};

export default Logo;
