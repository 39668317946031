import { Box, Grid, Grow, Stack, Typography } from "@mui/material";
import React from "react";

import { styles } from "./style";

const TeamItem = ({ data, index, visible }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Grow key={index} in={visible} timeout={1000 + index * 500}>
        <Stack spacing={-7} sx={styles.item}>
          <Box sx={styles.itemImageContainer}>
            <Box
              sx={{
                ...styles.itemImage,
                background: `url(${data.image})`,
              }}
            ></Box>
          </Box>

          <Stack
            alignItems="center"
            justifyContent={"center"}
            spacing={1}
            sx={styles.itemBody}
            width="100%"
          >
            <Typography sx={styles.itemHeader}>{data.header}</Typography>

            <Typography sx={styles.itemText}>{data.content}</Typography>
          </Stack>

          <Box sx={styles.itemAction}>{data.action}</Box>
        </Stack>
      </Grow>
    </Grid>
  );
};

export default TeamItem;
