import {
  Box,
  Button,
  Fade,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import Header from "../Header";
import SectionLayout from "../../layouts/SectionLayout";
import SlideTransition from "../SlideTransition";

import useContent from "../../hooks/useContent";
import useInView from "../../hooks/useInView";
import { MAIN_ROUTES } from "../../utils/routes";

import { styles } from "./style";

const ContactSection = () => {
  const content = useContent();
  const [setRef, visible] = useInView({
    threshold: 0,
  });
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  let navigate = useNavigate();

  return (
    <SectionLayout>
      <Stack ref={setRef} spacing={12} sx={styles.contactRoot}>
        <SlideTransition appear={visible} timeout={800}>
          <Stack spacing={2}>
            <Header
              center={matches ? true : true}
              color={"white"}
              data={content.contact}
              decorationPos={"bottom"}
            />

            <Typography sx={styles.bodyText}>
              {content.contact.content}
            </Typography>
          </Stack>
        </SlideTransition>

        <SlideTransition appear={visible} timeout={900}>
          <Button
            onClick={() => navigate(MAIN_ROUTES.get("CONTACT").PATH)}
            sx={styles.button}
            variant="contained"
          >
            {content.contact.action}
          </Button>
        </SlideTransition>
      </Stack>

      <Box sx={styles.background}>
        <Box sx={styles.backgroundOverlay} />
      </Box>
    </SectionLayout>
  );
};

export default ContactSection;
