import { Box, Container, Stack } from "@mui/material";
import React from "react";

import { styles } from "./style";

const SectionLayout = ({ children, id }) => {
  return (
    <Box component="div" id={id} sx={styles.sectionContainer}>
      <Container maxWidth="lg">
        <Stack spacing={0}>{children}</Stack>
      </Container>
    </Box>
  );
};

export default SectionLayout;
