import { Box, Fade, Grid, Grow, Stack, Typography } from "@mui/material";
import React from "react";

import Header from "../Header";
import SectionLayout from "../../layouts/SectionLayout";
import SlideTransition from "../SlideTransition";

import useContent from "../../hooks/useContent";
import useInView from "../../hooks/useInView";
import { MAIN_ROUTES } from "../../utils/routes";

import { styles } from "./style";

const Features = () => {
  const content = useContent();
  const [setRef, visible] = useInView({
    threshold: 0.07,
  });

  return (
    <SectionLayout id={MAIN_ROUTES.get("FEATURES").ID}>
      <Stack spacing={10} sx={styles.root}>
        {/* <SlideTransition appear={visible} timeout={800}> */}
          <Stack spacing={6} alignItems={"center"}>
            <Header
              center={true}
              // color={"white"}
              data={content.features}
              decorationPos={"bottom"}
            />

            <Typography sx={styles.bodyText}>
              {content.features.body}
            </Typography>
          </Stack>
        {/* </SlideTransition> */}

        <Grid
          container
          direction={"row"}
          ref={setRef}
          spacing={4}
          sx={styles.body}
        >
          {content.features.items.map((item, idx) => {
            return (
              <Grid item key={idx} xs={12} sm={6} md={3}>
                <Grow in={visible} timeout={1000 + idx * 500}>
                  <Stack spacing={8} sx={styles.item}>
                    {/* Image */}
                    <Box sx={styles.itemImageContainer}>
                      <Box
                        sx={{
                          ...styles.itemImage,
                          background: `url(${item.image})`,
                        }}
                      />
                    </Box>

                    {/* Content */}
                    <Fade in={visible} timeout={600 + idx}>
                      <Stack spacing={3}>
                        <Typography sx={styles.itemHeader}>
                          {item.header}
                        </Typography>

                        <Typography sx={styles.itemBody}>
                          {item.content}
                        </Typography>
                      </Stack>
                    </Fade>
                  </Stack>
                </Grow>
              </Grid>
            );
          })}
        </Grid>

        {/* <Box sx={styles.backgroundOverlay} /> */}

        {/* <Box sx={styles.backgroundOverlayBefore} /> */}
        {/* <Box sx={styles.backgroundOverlayAfter} /> */}
        <Box sx={styles.background} />
      </Stack>
    </SectionLayout>
  );
};

export default Features;
