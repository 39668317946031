import aixdb_may_2024_image_1 from "../assets/events/aixdb_may_2024/aixdb_may_2024_image_1.png";
import aixdb_may_2024_image_2 from "../assets/events/aixdb_may_2024/aixdb_may_2024_image_2.png";
import aixdb_may_2024_image_3 from "../assets/events/aixdb_may_2024/aixdb_may_2024_image_3.png";
import aixdb_may_2024_thumbnail from "../assets/events/aixdb_may_2024/aixdb_may_2024_thumbnail.png";

const events = [
  {
    status: "completed",
    header: "Past Events",
    items: [
      /** Content Template */

      {
        action: "Learn More",
        content: [
          // "With the rapid advancements in artificial intelligence (AI), we are witnessing a surge in AI-powered applications across sectors, which aim to relieve the burden on end-users by offering users personalized, automated AI-enhanced analytics and predictions. In the healthcare sector, for example, clinicians require AI-powered assistance for disease progression modeling. Similarly, in the financial industry, AI-driven algorithms can analyze market trends, manage portfolios, and even predict financial risks.",
          // "At present, the realms of AI models and data systems (aka databases or data management systems) exist as independent fields, each fundamental in its own right but isolated in functionality. This separation makes the construction of emerging AI applications fundamentally costly. Data scientists need to design an AI model and create a data system sepreately, navigating the complexities of both systems, which hinders the user-friendliness and operational efficiency of AI applications.",
          "The 1st International Workshop on AIxDB was successfully held at the National University of Singapore (NUS). This workshop aimed to explore the latest research breakthroughs and innovative techniques at the fusion of AI and databases (DB), breaking down barriers that have prevented end-users and data systems from fully embracing AI technologies.",
          "The workshop commenced on May 29 at 10 AM with Prof. Ooi hosting and introducing the vision for developing NeurDB. Prof. Zhang Meihui from Beijing Institute of Technology delivered a keynote on understanding the application and challenges of large language models (LLMs) from a data management perspective.",
          "Following the keynote, Zhanhao presented the development roadmap for NeurDB. Students from the NUS DBsystem group, including Naili, Jiaqi, Jingxuan, and Lingze, shared their recent research work that can potentially be integrated into NeurDB.",
          "Researchers, developers, and practitioners participated in the workshop and engaged in rigorous discussions, setting the direction for further development of next-generation data systems that meet end-users' needs for intelligent and autonomous data management.",
        ],
        date: "29 May 2024",
        images: [
          { alt: "aixdb_may_2024_image_1", image: aixdb_may_2024_image_1 },
          { alt: "aixdb_may_2024_image_2", image: aixdb_may_2024_image_2 },
          { alt: "aixdb_may_2024_image_3", image: aixdb_may_2024_image_3 },
        ],
        path: "2024-05-29-aixdb-workshop",
        thumbnail: aixdb_may_2024_thumbnail,
        title: "AIxDB 2024 Workshop",
        venue: "NUS, Singapore",
      },
    ],
  },
  {
    status: "upcoming",
    header: "Upcoming Events",
    items: [
      /** Content Template */
      // {
      //   action: "Learn More",
      //   content: [
      //     "With the rapid advancements in artificial intelligence (AI), we are witnessing a surge in AI-powered applications across sectors, which aim to relieve the burden on end-users by offering users personalized, automated AI-enhanced analytics and predictions. In the healthcare sector, for example, clinicians require AI-powered assistance for disease progression modeling. Similarly, in the financial industry, AI-driven algorithms can analyze market trends, manage portfolios, and even predict financial risks.",
      //     "At present, the realms of AI models and data systems (aka databases or data management systems) exist as independent fields, each fundamental in its own right but isolated in functionality. This separation makes the construction of emerging AI applications fundamentally costly. Data scientists need to design an AI model and create a data system sepreately, navigating the complexities of both systems, which hinders the user-friendliness and operational efficiency of AI applications.",
      //     "To dismantle the barriers preventing end-users from fully embracing AI technologies, data systems, the cornerstone of mainstream online applications integral to our everyday lives, are set to experience a transformative revolution with the imminent fusion of AI and DB (AIxDB). This workshop, the 1st International Workshop on AIxDB, aims to explore this synergy, delving into the latest research breakthroughs and innovative techniques at the fusion of AI and DB.",
      //     "Despite considerable progress in integrating AI with data systems, a significant gap remains between the potential of this integration and its current state of usability. Bridging this gap requires a seamless integration of AI and database technologies, creating a unified data system that transcends the capabilities of each in isolation. Through engaging researchers, developers, and practitioners in rigorous discussions, the workshop seeks to advance the development of next-generation data systems that meet the needs of end-users for intelligent and autonomous data systems.",
      //   ],
      //   date: "29 May 2024",
      //   images: [
      //     { alt: "aixdb_may_2024_image_1", image: aixdb_may_2024_image_1 },
      //     { alt: "aixdb_may_2024_image_2", image: aixdb_may_2024_image_2 },
      //     { alt: "aixdb_may_2024_image_3", image: aixdb_may_2024_image_3 },
      //   ],
      //   path: "2024-05-29-aixdb-workshop",
      //   thumbnail: aixdb_may_2024_thumbnail,
      //   title: "AIxDB 2024 Workshop",
      //   venue: "NUS, Singapore",
      // },
    ],
  },
];

export default events;
