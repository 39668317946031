import { Box, Slide } from "@mui/material";
import React from "react";

const SlideTransition = ({ appear, children, timeout }) => {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Slide
        direction="up"
        in={appear}
        mountOnEnter
        timeout={timeout}
        unmountOnExit
      >
        {children}
      </Slide>
    </Box>
  );
};

export default SlideTransition;
