import { MAIN_ROUTES } from "./routes";

export const NAV_OPTIONS = new Map([
  ["HOME", MAIN_ROUTES.get("OVERVIEW")],
  ["PEOPLE", MAIN_ROUTES.get("PEOPLE")],
  ["PUBLICATIONS", MAIN_ROUTES.get("PUBLICATIONS")],
  ["RESOURCES", MAIN_ROUTES.get("RESOURCES")],
  ["GITHUB", MAIN_ROUTES.get("GITHUB")],
  ["CONTACT", MAIN_ROUTES.get("CONTACT")],
]);

export const LANGUAGES_OPTIONS = new Map([
  [
    "ENGLISH",
    {
      LABEL: "English",
      KEY: "en",
    },
  ],
  [
    "CHINESE",
    {
      LABEL: "中文",
      KEY: "zh",
    },
  ],
]);
