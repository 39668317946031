export const styles = {
  sectionContainer: {
    alignItems: "start",
    display: "flex",
    justifyContent: "center",
    pb: { xs: 24, md: 36 },
    pt: { xs: 18, md: 36 },
    position: "relative",
    width: "100%",
  },
};
