import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";

import useInView from "../../hooks/useInView";

import { styles } from "./style";

const OverviewItem = ({ data, index, setCurrentIndex }) => {
  const [setRef, visible] = useInView({
    threshold: 1,
  });
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    if (visible) {
      setCurrentIndex(index);
    }
  }, [visible]);

  return (
    <Stack spacing={6} sx={styles.item}>
      <Stack ref={setRef} spacing={3} sx={styles.itemHeader}>
        <Box sx={styles.itemBullet}>{index + 1}</Box>
        <Typography sx={styles.itemHeaderText}>{data.header}</Typography>
      </Stack>

      <Typography sx={styles.itemText}>{data.content}</Typography>

      {!matches && (
        <img src={data.image} alt={data.header} style={styles.itemImage} />
      )}
    </Stack>
  );
};

export default OverviewItem;
